/**
 * This file was automatically generated with 'graphql-code-generator' and should not be edited.
 * To regenerate this file update some *.graphql file and run `yarn codegen`
 *
 * See settings in project file: 'codegen.ts'
 */

/* eslint-disable */

import * as Types from '../../../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CertificationDetailFragment = {
  __typename?: 'Certification';
  id: any;
  archivedAt?: any | null;
  name: string;
  validFrom?: any | null;
  validTo?: any | null;
  validityInMonths?: number | null;
  description: string;
};

export type CertificationDetailQueryVariables = Types.Exact<{
  certificationDetailId: Types.Scalars['CertificationID']['input'];
}>;

export type CertificationDetailQuery = {
  __typename?: 'Query';
  object:
    | {
        __typename?: 'Certification';
        id: any;
        archivedAt?: any | null;
        name: string;
        validFrom?: any | null;
        validTo?: any | null;
        validityInMonths?: number | null;
        description: string;
      }
    | { __typename?: 'ObjectDoesNotExistResponse'; objectId?: string | null };
};

export const CertificationDetailFragmentDoc = gql`
  fragment CertificationDetail on Certification {
    id
    archivedAt
    name
    validFrom
    validTo
    validityInMonths
    description
  }
`;
export const CertificationDetailDocument = gql`
  query CertificationDetail($certificationDetailId: CertificationID!) {
    object: certificationDetail(id: $certificationDetailId) {
      ... on Certification {
        ...CertificationDetail
      }
      ... on ObjectDoesNotExistResponse {
        objectId
      }
    }
  }
  ${CertificationDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class CertificationDetailGQL extends Apollo.Query<CertificationDetailQuery, CertificationDetailQueryVariables> {
  override document = CertificationDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
